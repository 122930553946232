.img_login {
    z-index:-1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por12c.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .App-header_login {
    background-color: 'transparent';
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .box_black {
    display: flex;
    flex-direction: column;
    background-color: rgba(41, 41, 41, 0.9);
    border-radius: 15px;
    padding: 25px;
    width: 70%;
    height: 70%;
    align-items: center;
    justify-content: center;
  }

  .img_camaras {
    z-index:-1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por8c.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .title_login {
    font-size: 36px;
    color: rgba(239, 239, 239, 0.8);
    margin: 15px;
    margin-bottom: 10px;
  }

  .color {
    color: #f9f9f9;
  }
